var OK = window.OK || (window.OK = {});

OK.reg = (function(wnd) {

    var ATTR_PREFIX = 'data-',
        ATTR_PREFIX_LEN = ATTR_PREFIX.length,
        HINT_SUFFIX = '.dscr',
        HINT_SUFFIX_LEN = HINT_SUFFIX.length,
        CLONE_ATTRIBUTES = ['id', 'name', 'maxlength', 'autocomplete', 'placeholder'],
        CLONE_ATTRIBUTES_LEN = CLONE_ATTRIBUTES.length,
        document = wnd.document,
        okLoggingLogger = OK.logging.logger,
        okUtilExtend = OK.util.extend;

    function getElementById(id) {
        return document.getElementById(id);
    }

    function startsWith(str, start) {
        if (str.startsWith) {
            return str.startsWith(start);
        } else {
            return str.indexOf(start) == 0;
        }
    }

    function endsWith(str, end) {
        if (str.endsWith) {
            return str.endsWith(end);
        } else {
            var i = str.length - end.length;

            return i >= 0 && str.indexOf(end, i) == i;
        }
    }

    // depends on jQuery's toggleClass
    function initErrorSupport(fieldId, settings) {
        settings = okUtilExtend(settings || {}, {
            curErr: 'cur.err',      // name of the attr, containing current error in the field, if any
            defaultHint: 'hint',    // name of the attr, containing default hint, if any
            errorDelay: 300,        // delay between detection of error & displaying it
            errorSwapDelay: 300     // delay between hiding prev error & showing the new one
        });

        // parse container attributes & remove source container when done
        // return {errKey: str, {message: str, hint: str}}
        function parseErrors(containerId) {
            var msgEl = getElementById(containerId + 'Mssgs'),
                errByKey = {},
                i,
                len,
                attributes,
                node,
                name,
                value,
                hint,
                msg;
            if (msgEl) {
                for (i = 0, attributes = msgEl.attributes, len = attributes.length; i < len; ++i) {
                    node = attributes.item(i);
                    name = node.name;
                    if (startsWith(name, ATTR_PREFIX)) {
                        value = node.value;
                        if (value) {
                            name = name.substring(ATTR_PREFIX_LEN);
                            if (name == settings.curErr) {
                                curErrKey = value;
                            } else if (name == settings.defaultHint) {
                                defaultHint = value;
                            } else {
                                hint = endsWith(name, HINT_SUFFIX);
                                if (hint) {
                                    name = name.substring(0, name.length - HINT_SUFFIX_LEN);
                                }
                                msg = errByKey[name] || {};
                                errByKey[name] = okUtilExtend(msg, hint ? {hint: value} : {message: value});
                            }
                        }
                    }
                }
                msgEl.parentNode.removeChild(msgEl);
            }

            return errByKey;
        }

        function setErrKeyImmediate(errKey) {
            var err,
                swapDelay = errKey && curErrKey;
            curErrKey = errKey;
            if (errKey) {
                errKey = errKey.toLowerCase();
                err = okUtilExtend(errByKey[errKey] || {message: errKey}, {hint: defaultHint});
            } else {
                err = {hint: defaultHint};
            }

            function showError(show) {
                showErrFrameEl.toggleClass('form_i__error', show);
            }

            function applyError() {
                errTextEl.innerHTML = err.message || '';
                hintTextEl.innerHTML = err.hint || '';
                showError(errKey);
            }

            if (swapDelay) {    // changing existing error to another - apply swap delay
                showError(false);
                wnd.setTimeout(function () {applyError();}, settings.errorSwapDelay);
            } else {
                applyError();
            }
        }

        var containerId = fieldId + 'Prompt',
            showErrFrameEl = $('#' + fieldId + 'Form'),     // element to apply error frame class to
            errTextEl = getElementById(containerId + 'Err'),    // element for err msg
            hintTextEl = getElementById(containerId + 'Hint'),  // element for hint
            curErrKey,  // error key being displayed at the moment
            newErrKey,  // pending error key, if any
            defaultHint,
            errByKey = parseErrors(containerId),    // {errKey: str, {message: str, hint: str}}
            errTimer;   // implements error display delay

        return {
            settings: settings,
            setErrKey: function (errKey) {
                if (!errKey && curErrKey) {
                    if (errTimer) {
                        wnd.clearTimeout(errTimer);
                    }
                    setErrKeyImmediate(newErrKey = errKey);
                } else if (errKey != newErrKey) {
                    newErrKey = errKey;
                    if (!errTimer) {
                        errTimer = wnd.setTimeout(function() {
                            errTimer = '';
                            setErrKeyImmediate(newErrKey);
                        }, settings.errorDelay);
                    }
                }
            }
        };
    }

    // switches between text/password modes;
    // implemented by replacing input field in DOM tree;
    // input must be accompanied with checkbox, which is to trigger switching
    function togglePwdVisible(id) {
        try {
            var field = getElementById(id),
                type = getElementById(id + 'Check').checked ? 'password' : 'text',
                clone = document.createElement('input'),
                attrName,
                attrVal,
                i;

            clone.setAttribute('type', type);
            for (i = 0; i < CLONE_ATTRIBUTES_LEN; ++i) {
                attrName = CLONE_ATTRIBUTES[i];
                attrVal = field.getAttribute(attrName);
                if(attrVal) {
                    clone.setAttribute(attrName, attrVal);
                }
            }
            clone.value = field.value;
            clone.className = field.className;
            field.parentNode.replaceChild(clone, field);
            initPwdErrorSupport(id, id + 'Cntnr');
            window.setTimeout(function() {
                try {
                    clone.focus();
                } catch(e) {
                    okLoggingLogger.error('reg.tgl.2', e);
                }
            }, 100);
        } catch(e) {
            okLoggingLogger.error('reg.tgl.1', e);
        }
    }

    // initializes real-time checks for password field with fieldId
    // containerId - optional id of container to store real-time check data
    function initPwdErrorSupport(fieldId, containerId, settings) {
        OK.loader.use(['jQuery'], function() {
            containerId = containerId || fieldId;
            settings = okUtilExtend(settings || {}, {
                minLen: 6,
                maxLen: 50,
                pwdPattern: '^[0-9a-zA-Z\\Q!@#$%^&*()_\\-+.\\E]*$'
            });

            var cnt = getElementById(containerId),
                errorHolder = cnt.errSup || (cnt.errSup = initErrorSupport(fieldId)),
                oldVal = '',
                pwdPattern = settings.pwdPattern && new RegExp(settings.pwdPattern),
                request;

            // exists for compatibility with msg keys from registration being used at the moment
            function toErrKey(fieldName, errName) {
                return errName && 'errors.' + fieldName + '.' + errName + '.nologin';
            }

            function changeHandler() {
                var val = $(this).val(),
                    len = val && val.length,
                    error,
                    fieldName = $(this).attr('name'),
                    name = fieldName.substr(fieldName.indexOf('.') + 1);

                if (val != oldVal) {
                    oldVal = val;
                    if (!len) {
                        error = 'empty';
                    } else if (len < settings.minLen) {
                        error = 'short';
                    } else if (len > settings.maxLen
                        || (!pwdPattern || !pwdPattern.test(val))) {
                        error = 'wrong';
                    }
                    if (error) {
                        errorHolder.setErrKey(toErrKey(name, error));
                    } else {
                        request = $.ajax({
                            url: '/dk?cmd=RegCheckPwdStrength',
                            data: {"sg.serializedQueryBean":'0;' + val,
                                "gwt.requested":window.pageCtx.gwtHash},
                            timeout: 2000
                        }).done(function(data, st, xhr) {
                            if (xhr == request) {   // ignore response in case another request has already been sent
                                var json = OK.util.parseJSON(data);
                                errorHolder.setErrKey(toErrKey(name, json && json.errorMessage, error));
                            }
                        }).fail(function(xhr) {
                            if (xhr == request) {   // ignore response in case another request has already been sent
                                errorHolder.setErrKey();
                            }
                        });
                    }
                }
            };
            $('#' + fieldId).bind("keyup change", changeHandler);
        });
    }

    return {
        tglPwd: togglePwdVisible,
        initPwdErr: initPwdErrorSupport
    };
})(window);
